import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';
import { Curso } from '../../../models/curso.model';
import { Personal } from '../../../models/personal.model';
import { Reserva } from '../../../models/reserva.model';
import { CursoService, UserService } from '../../../services/service.index';

@Component({
  selector: 'app-cursoscomplementario',
  templateUrl: './cursoscomplementario.component.html',
  styles: []
})
export class CursoscomplementarioComponent implements OnInit {

  title: string;
  dtOptions: DataTables.Settings = {};
  numero: number = 0;
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  totalRegistros2: number = 0;
  totalRegistrosi: number = 0;
  totalRegistrosp: number = 0;
  totalRegistross: number = 0;
  cargando: boolean = true;DTF
  cursos: Curso[] = [];
  reserva: Reserva[] = [];
  reservai: Reserva[] = [];
  reservap: Reserva[] = [];
  reservas: Reserva[] = [];
  reservat: Reserva[] = [];
  reservan: Reserva[] = [];
  tutor: Curso[] = [];
  personal: Personal
  constructor(
    public _cursoService: CursoService,
    public _userService: UserService,
    public router: Router
  ) { 
    this.title = "Listas Gestión 2025";
    this.dtOptions = {
      pagingType: 'simple',
      pageLength: 10,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.12.0/i18n/es-ES.json'
      },
      order: [[0, 'asc']]
    };
  }

  ngOnInit() {
    this.cargarCurso();
    this.cargarCursoTutor();
    this.cargarReserva();
    this.cargarReservaInicial();
    this.cargarReservaPrim();
    this.cargarReservaSec();
    this.cargarReservaT();
    this.cargarReservaN();
    this.personal = this._userService.personal;
  }
  cargarCursoTutor(){
    this._cursoService.cargarCursoSinSin()
        .subscribe( curso => {
          this.tutor = curso;
        })
  }
  cargarReserva(){
    this._userService.cargarReserva(this.desde)
        .subscribe( (resp: any) => {
          this.reserva = resp.personal;
          this.totalRegistros2 = resp.cuantos;
        })
  }
  cargarReservaN(){
    this._userService.cargarReservaNivel()
        .subscribe( (resp: any) => {
          this.reservan = resp.personal;
          //console.log(this.reservan);
        })
  }
  cargarReservaT(){
    this._userService.cargarReservaT()
        .subscribe( (resp: any) => {
          this.reservat = resp.personal;
          //console.log(this.reservat);
        })
  }
  cargarCurso(){
    this._cursoService.cargarCursoSinlimite(  )
        .subscribe( (resp: any) => {
          this.cursos = resp;
          this.cargando = false;
        })
  }
  cargarReservaInicial(){
    this._userService.cargarReservaInicial()
        .subscribe( (resp: any) => {
          this.reservai = resp.personal;
        })
  }
  cargarReservaPrim(){
    this._userService.cargarReservaPrim()
        .subscribe( (resp: any) => {
          this.reservap = resp.personal;
        })
  }
  cargarReservaSec(){
    this._userService.cargarReservaSec()
        .subscribe( (resp: any) => {
          this.reservas = resp.personal;
        })
  }
  /*cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarCurso();
  }*/
  cambiarDesde2( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros2 ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarReserva();
  }

  borrarRegistro( reserva: Reserva ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de borrar al estudiante: '+reserva.paterno,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._userService.borrarEstudiante( reserva._id )
          .subscribe( virtual => {
            this.cargarReserva();
            this.cargarReservaInicial();
            this.cargarReservaPrim();
            this.cargarReservaSec();
            this.cargarReservaT();
          });
      }
    })
  }

  /*QuitarTutor( curso: Curso ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de ya no ser tutor de: '+curso.nombre,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._personalService.bajaPersonal( personal._id )
              .subscribe( personal => {
                this.cargarPersonal();
              });
      }
    })
  }*/

  AsignarTutor( curso: Curso ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de ser tutor de: '+curso.nombre,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._cursoService.asignarTutor( curso._id, this.personal[0]._id )
              .subscribe( curso => {
                this.cargarCurso();
                this.cargarCursoTutor();
              });
      }
    })
  }

  verLista(id: string){
    this.router.navigate(['/plataforma/lista-curso-complementario', id]);    
  }
  corregirDatos(id: string){
    this.router.navigate(['/plataforma/correcion-datos', id]);    
  }

  cursoDocente(id: string){
    this.router.navigate(['/plataforma/materia-docente', id]);    
  }

}
